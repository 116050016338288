<template>
  <div class="systemLog-list-container">
    <b-card no-body>
      <b-card-header>
        <b-card-title>Lịch sử thao tác</b-card-title>

      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="6">
            <select-manager
              v-model="manager"
              :include-owner="true"
            />
          </b-col>
          <b-col md="6">
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm ..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-systemLog-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    :variant="props.row.statusObject.variant"
                    class="font-small-1"
                  >
                    <span>{{ props.row.statusObject.title }}</span>
                  </b-badge>
                </span>

                <!-- Column: Default -->
                <span v-else-if="props.column.field === 'createdAt'">
                  {{ parseDateToString(props.row.createdAt, 'DD-MM-YYYY HH:mm') }}
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="Nhà cung cấp"
                    modal="modal-systemLog"
                    :hide-delete="!$can('delete', 'systemLog')"
                    :hide-edit="!$can('update', 'systemLog')"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />

                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
  BFormInput,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
// eslint-disable-next-line import/no-cycle
import { parseDateToString } from '@/auth/utils';

import useSystemLogList from './useSystemLogList';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    TableActions,
    VueGoodTable,
    BBadge,
    BFormInput,
  },

  setup() {
    const {
      item,
      resetItem,
      manager,
      columns,
      rows,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchSystemLogs,
      refetchSystemLogs,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
      onSystemLogUpdated,
    } = useSystemLogList();

    return {
      item,
      resetItem,
      columns,
      manager,
      rows,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      refetchSystemLogs,
      fetchSystemLogs,

      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      onEditItem,
      parseDateToString,
      onSystemLogUpdated,
    };
  },

  created() {
    this.fetchSystemLogs();
  },
  sockets: {
    // importProcessUpdate(data) {
    // if (data && data.status === 'running' && data.items.length === 1) {
    //   this.fetchSystemLogs();
    // } else if (data && (data.status === 'success' || data.status === 'fail')) {
    //   this.fetchSystemLogs();
    // }
    // },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

</style>
