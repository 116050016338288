import {
  ref, watch, onUnmounted,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import systemLogStoreModule from '../systemLogStoreModule';

export default function useSystemLogList() {
  const STORE_MODULE_NAME = 'systemLog';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, systemLogStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  // add / edit
  const blankItem = {
    id: 0,
    name: '',
    phone: '',
    contactName: '',
    address: '',
    url: '',
    note: '',
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Chức năng',
      field: 'functionName',
    },
    {
      label: 'Mô tả',
      field: 'description',
    },
    {
      label: 'Thời gian',
      field: 'createdAt',
    },
    {
      label: 'Người thực hiện',
      field: 'user.name',
    },
  ];
  const rows = ref([]);
  const manager = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  const fetchSystemLogs = () => {
    store
      .dispatch('systemLog/fetchSystemLogs', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchSystemLogs();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  const onSystemLogUpdated = systemLog => {
    const temp = rows.value;
    for (let index = 0; index < temp.length; index += 1) {
      if (temp[index].id === systemLog.id) {
        temp[index] = systemLog;
      }
    }
    rows.value = temp;
  };

  //   Watch
  watch(manager, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.userId = val.id;
    } else {
      delete filter.userId;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  //   API Call

  const refetchSystemLogs = () => {
    fetchSystemLogs();
  };

  return {
    item,
    resetItem,

    columns,
    rows,
    selectedRows,
    searchTerm,
    manager,
    isLoading,
    totalRecords,
    serverParams,

    refetchSystemLogs,
    fetchSystemLogs,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
    onSystemLogUpdated,
  };
}
